import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';

import './Tab2.css';

const Tab2: React.FC = () => {

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Our Videos</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Our Videos</IonTitle>
          </IonToolbar>
        </IonHeader>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/Uf_Ux8wEq1M" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/8-7gnVhFGsU" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/di1gYH_fI-Y" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/J3PAl2smBw8" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/hyHudc7TA6Y" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/P-6wQqKT3gw" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/S9fGXBkj6p8" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/rezZgso80CQ" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/u0bz8S-k218" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/tLMrbPj-ijQ" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/dJ-bKACC01k" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/NVAKQSs21Ow" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/j6IAnb3KcIE" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/2hVSIvt6iUk" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        <iframe className="youtube-frame" frameBorder={'0'} allowFullScreen={true} src="https://www.youtube.com/embed/eZv4BxOx_CA" title="YouTube video player" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
        
      </IonContent>
    </IonPage>
  );
};

export default Tab2;
