import { IonContent, IonHeader, IonInput, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';

import './Tab1.css';

import { FAQ } from '../utils/FAQ';
import { useEffect, useState } from 'react';

const Tab1: React.FC = () => {
  const [filter, setFilter] = useState<string>();
  const [text, setText] = useState<string>();
  const [openArticle, setOpenArticle] = useState<number>();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>FAQ</IonTitle>
        </IonToolbar>
      </IonHeader>
      
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">FAQ</IonTitle>
          </IonToolbar>
        </IonHeader>
        {/* <IonInput value={text} placeholder="Search..." onIonChange={e => setText(e.detail.value!)}></IonInput> */}

        <div className="faq-container">
          {FAQ.map((answer, i) => {
            return(
              <div key={answer.title + i} style={{marginBottom: '10px'}}>
                <h1 onClick={()=>setOpenArticle(i)}>{answer.title}</h1>
                <p className={openArticle == i ? "open" : ""}>{answer.answer}</p>
              </div>
            )
          })}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
