const FAQ = [
  {
    title: "How do I qualify to claim from the RAF?",
    answer:
      "If you were involved in a motor vehicle accident which cannot be contributed to your sole, negligence and you were severely injured you should have reasonable prospects of success,with a claim against the RAF. Do I have to be 30% WPI (Whole Person Impaired) to claim General Damages? No – kindly refer to the “Case Studies” banner.",
  },
  {
    title: "How long do I have to lodge a claim against the RAF?",
    answer:
      "As a general rule you have 3(three) years from date of accident to lodge a claim against the RAF. However, a distinction is made between identified (identity of the other party is known) and un-identified (or so called hit-and-run) claims. Where the identity of the other party/insured driver is not known a claimant has 2 (two) years from date of accident to lodge a claim. Our advice would be to get started with a claim as soon as reasonably possible after the accident as witnesses, doctors, documents, information etc. tend to get more difficult to get hold of as time goes by.",
  },
  {
    title: "Do I have to lodge a severe injury assessment?",
    answer:
      "A new RAF Act was implemented on 1 August 2008 governing all accidents that took place after this date. The act requires prospective claimants (in all personal injury matters) to submit themselves to a severe injury assessment, as stipulated by the guidelines of the American Medical Association (AMA) in order to qualify for general damages i.e pain and suffering, loss of amenities etc. The rest of the heads of damages like past medical expenses, future medical expenses, loss of earnings or earning capacity are still available to be claimed upon proof thereof. The new act requires a much more involved relationship between attorney and client as the attorney needs to be constantly aware of changes in the client’s personal situation and recovery process to ensure the optimal outcome of any possible claim. For this reason, it is very important to ensure that you instruct the right attorney,someone that you would feel comfortable with as you will be dealing with them for a certain period of time and are specialized in this field of the law.",
  },
  {
    title: "Road Accident Fund, Bankrupt?",
    answer:
      "In a recent article, a prominent newspaper made mention that the RAF was insolvent. Upon enquiry the reporter acknowledged that she had done no research in this regard and that the report was basically a “copy and paste” scenario taken from similar articles of previous years. The RAF was created by the State as social legislation to compensate injured road users once a claim had been proven. In terms of the RAF legislation an injured party is prevented from claiming against the wrongdoer and is obliged to claim from the RAF. As such there would always have to be enough available funds to compensate claimants in the absence of which the Minister would be held accountable. We really hope the press would stop spreading discord and start respecting the people who are wholly or partly dependent on the outcome of their claims. In many instances, the payment by the Fund constitutes a last paycheck or only hope to recover the last dignity they have or place them in a position to obtain the much anticipated medical treatment they so urgently require. These unfounded reports only contribute to the claimants agony and should be ignored with the necessary contempt it deserves.",
  },
  {
    title: "Do I need to have a licence to claim from the RAF?",
    answer:
      "The answer simply is : No. The question the RAF is interested in is who was to blame for the accident and to what extent? A person driving without a license might have committed a statutory offense but it does not exclude access to claiming compensation from the RAF. The mere fact that a person was driving without a license is not negligence in itself.",
  },
  {
    title:
      "Do I need to be injured on a national road to qualify to claim from the Road Accident Fund?",
    answer:
      "As long as a person is not the sole cause of the accident and injured in or by a vehicle as required in terms of the Road Accident Fund Act you would be able to claim from the Fund irrespective of where the accident took place. The test to determine whether a vehicle is a motor vehicle is an objective one and it can only be said that a vehicle was designed or adapted for propulsion or haulage on a road by means of fuel, gas or electricity if it is reasonably suitable for that purpose, i.e if it can be driven on a road at the same time the road is being used by other road users. Attributes of a particular vehicle will determine whether or not it is a “motor vehicle” as required.",
  },
  {
    title: "What may be claimed under the heading of Funeral Expenses?",
    answer:
      "Ceremonial expenses and tombstones are excluded. Funeral Expenses are limited to the costs of the cremation of the deceased or the actual burial of the deceased. Tombstones, flowers, flyers and any food and beverages are accordingly excluded. Important to note that the RAF will only consider specified accounts and as a general rule only the person who paid for the expenses may claim except if a proper cession is provided in favor of someone else.",
  },
  {
    title:
      "Does the lodgement of claim documents via telefax consitute substantial compliance?",
    answer:
      "During a meeting held between the then MMF and the Standing Committee on MVA matters of the Association of Law Societies of South Africa it was generally agreed that if claim documents were timeously submitted by telefax, the principle of “substantial compliance” should be applied. As such, if documents submitted by telefax were sufficiently complete and legible to avoid any prejudice on the part of the RAF the claim should be accepted as being in substantial compliance with the requirements of the Act and all its regulations. Take note however  that the original documents should immediately follow the lodgment of the faxed documents and there should be no material discrepancy between the faxed and the original documents. Our advice would still be to verge on the safer side of caution and lodge the claim in time either by hand or by registered post.",
  },
  {
    title: "When would a driver/owner be regarded as Identified?",
    answer:
      "The general rule is that the identity of the owner/driver must be known to the claimant at the time of the lodgment of the claim. A person can only be regarded as identified if his name and address are both furnished, even if the registration number is not. The address can be physical or a postal address, a residential or a work address, a telephone number, or a description of where the person may be found. In addition to a name and address a telephone number, identity number and vehicle registration number should also be given. A person will not be regarded as prima facie identified: if only a registration number of the vehicle is submitted; if only a registration number is submitted; if only a name is given. Claimants are advised to ensure that the details of the insured driver is correct before a claim is lodged as the RAF will be in a position to deliver a special plea or repudiate the claim should the details turn out to be incorrect.",
  },
  {
    title: "What are the RAF’s contact details?",
    answer:
      "Head office : 38 Ida Street, Menlo Park, Pretoria, Private Bag x2003, Menlyn, 0063 Tel : 012-4295000 / Fax : 012 – 429 5500, CALL CENTRE: 0860 23 55 23, www.raf.co.za",
  },
  {
    title:
      "What is considered Important Information and documentation in RAF matters?",
    answer:
      "SAPS Case Number and Name of relevant Police Station; Names and contact details of traffic officers attending a scene; Copy of Identity document; Copy of birth certificate in matters where children are involved; Salary advices for a period of at least 3 months before the accident; Full particulars of employers; Details of all treating doctors and/or medical personnel; Details of any witnesses; Photos of the scene of the accident; Photos of the vehicles involved; Photos of visible injuries; Specified funeral expenses accounts; Specified medical expenses; Medical Aid Undertaking where applicable; Keep a file folder handy in which to collect all medical expenses and documents related to the accident.",
  },
  {
    title: "Who may depose to a Section 19(f) Affidavit?",
    answer:
      "An affidavit is a sworn statement deposed to in front of a commissioner of oaths and signed by both the deponent and the commissioner. The affidavit should relate to the accident from which the claim arose and must fully set out where, when and how the accident occurred. It must also indicate which vehicles were involved and how they were involved. The person who was injured should preferably make the affidavit. In cases were the claimant is not the injured person for example where a child is injured and a parent or guardian claims on their behalf an affidavit should state this fact and also confirm whether they were present at the time of the accident. If the child is unable to depose to an affidavit an eyewitness or anybody else who was involved in the accident can also make an affidavit.",
  },
  {
    title:
      "Which Medical Practitioner may complete the Statutory Medical Report - RAF 1?",
    answer:
      "As a general rule the doctor or medical practitioner who treated the injured person or the Superintendent or representative of a hospital where the person was treated should complete the medical report. However, it is not a requirement that the report should be completed by either the first doctor who treated the injured after the accident. In the recent judgement of Judge BH Mbha in TR Mokoena v RAF, Case No.2010/38170, South Gauteng High Court, it was found that an injured person need not be physically examined by a doctor in order to substantially comply with the requirements of the act as far as the completion of the RAF 4, is concerned. It was held that the relevant records of the hospitals and medical institutions where the injured was treated would be enough evidence of the extent of the injured’s injuries. By the same token any medical practitioner, duly registered with the HPCSA, should be able to complete an RAF 1, if he/she had been placed in possession of the injured person’s medical and hospital records relating to the accident in question. However, to stay on the safe side always try and obtain the RAF 1, medical report from the first treating doctor and/or hospital but if prescription is imminent another doctor should be able to complete the form provided that the hospital/ medical records are available.",
  },
  {
    title: "How do I effectively use my Section 17(4)(a) - Undertaking?",
    answer:
      "A section 17(4)(a) – undertaking is a Statutory certificate given by the RAF after settlement of either the merits or the claim as a whole. The idea is to allow the claimant to recover their accident related medical expenses from the RAF. However, the “catch” is that it is required that the claimant first pays for the medical services or treatment after which the accounts have to be submitted to the Fund. It is important to note that if there should be any apportionment against the claimant that apportionment also applies to the certificate. For example if a 50% apportionment has been applied a claimant will only be able to recover 50% of the medical and related expenses from the Fund. It is further very important to note that the undertaking only covers medical expenses related to the accident and as such colds and flues etc. will not be covered. Such medical services may be obtained from any medical institution i.e claimant will be able to receive treatment from the best doctors in the country required that he/she pays up front. Once an account has been paid the original needs to be signed and sent to the relevant claims administrator at the RAF dealing with your file, together with a copy of your identity document and banking details. The reference number of this claims administrator will be printed on the undertaking. If the claims administrator are satisfied that the account is accident related the Fund will effect payment to you within a reasonable time. Should the Fund be reluctant to pay and/or no proper explanation is given our advice would be to try and arrange a meeting with the claims administrator if possible and if you are still not satisfied the matter should be referred to your attorney to address the issue with the Fund.",
  },
  {
    title: "Am I allowed to claim from the wrongdoer (insured driver)?",
    answer:
      "No, in terms of the current RAF Act you are not allowed to claim any compensation relating to personal injury from the wrongdoer.",
  },
  {
    title: "What can I claim from the RAF?",
    answer:
      "Past Medical expenses, Future Medical expenses, Loss of earnings / earning capacity, Loss of Support, Funeral Expenses, General Damages – required that the claimant qualifies with a: 30% Whole Person Impairment rating or under the so called “Narrative Test”.",
  },
  {
    title: "What is the basis of a damages claim?",
    answer:
      "The basis of any personal injury compensation is to place the road accident victim in the financial position that he/she would have been in had it not been for the accident. In other words the position before the accident needs to be compared with the position after the accident, the difference between the two equates to the loss/damage.",
  },
  {
    title: "How do we quantify a claim?",
    answer:
      "As a road accident fund victim bears the onus of proving the extent of his/her damages. In a matter where a person was injured and depending on the nature of the injury certain experts are engaged each one giving an opinion based upon his/her field of expertise, of how a particular injury has and will influence the injured in the future. The “once and for all rule” applies and as such we have to make absolutely certain that once a matter is settled the claimant will be medically and financially independent. Remember no two claims are ever alike.",
  },
  {
    title:
      "How do we decide whether a claim should be a High Court or a Magistrate Court matter?",
    answer:
      "The nature and extent of an injury dictates the value of a claim – the more severe the injury the larger the settlement. The moment the value or quantum of a claim exceeds R 300 000.00 a matter becomes a High Court matter.",
  },
  {
    title: "What information should I gather at an accident scene?",
    answer:
      "As much detail of the other party/driver as you can; As much detail of witnesses as you can (identity numbers, cellphone numbers and addresses etc.); Photos of the position of the vehicles; Photos of the damage to all vehicles involved; Details of any traffic or other police officers at the scene; Names and details of emergency personnel.",
  },
];

export { FAQ };
