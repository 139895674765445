import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle } from 'ionicons/icons';
import Tab1 from './pages/Tab1';
import Tab2 from './pages/Tab2';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useEffect, useState } from 'react';

// import coverVid from './media/Final.mp4';
import coverGif from './media/RAFAnimation.gif';
import iconHome from './media/icon-home.png';
import iconYouTube from './media/icon-YouTube.png';
import RAFBuddy from "./media/RAFBuddy.png";
import buddyStand from "./media/Videos/BuddyCropped.gif";

import "./_style.App.css";

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [showBuddy, setShowBuddy] = useState(false);

  let thing = true;

  useEffect(() => {
    if(loading){
      setTimeout(() => {
        setLoading(false);
      }, 12000);
    }

    let buddyBtn = document.getElementsByClassName("globalClass_044b");
    let closeBuddy = document.getElementsByClassName("headerContainer_07bf");

    if(buddyBtn && buddyBtn.length){
      buddyBtn[0].addEventListener("click", handleClick);
    }

  });

  const handleClick = () => {
    thing = false;
    setShowBuddy(!showBuddy);
  }  
  
return(
  <IonApp>
    {loading ? (
      <div className="full-overlay" onClick={() => setLoading(false)}>
        <img src={coverGif} alt="GertNelGif" />
        {/* <video src={"https://staging.pieterburger.co.za/RAFBuddy-video.mp4"} autoPlay muted={thing}/>
        <iframe src={"https://staging.pieterburger.co.za/RAFBuddy-audio.mp3"} allow="autoplay"></iframe> */}
      </div> 
    ) : (
      <></>
    )}
    
    <IonReactRouter>
      <img className={showBuddy ? "rafIcon show" : "rafIcon"} src={buddyStand} alt="RAFBuddyClick" onClick={handleClick}/>

      <IonTabs>
        <IonRouterOutlet>
          <Route exact path="/home">
            <Tab1 />
          </Route>
          <Route exact path="/ourVideos">
            <Tab2 />
          </Route>
          {/* <Route path="/tab3">
            <Tab3 />
          </Route> */}
          <Route exact path="/">
            <Redirect to="/home" />
          </Route>
        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="home" href="/home">
            {/* <IonIcon icon={triangle} /> */}
            <img className="icon" src={iconHome} alt="YouTubePlaylist" />
            <IonLabel>Home</IonLabel>
          </IonTabButton>
          <IonTabButton tab="ourVideos" href="/ourVideos">
            {/* <IonIcon icon={ellipse} /> */}
            <img className="icon" src={iconYouTube} alt="YouTubePlaylist" />
            <IonLabel>Our Videos</IonLabel>
          </IonTabButton>
          {/* <IonTabButton tab="tab3" href="/tab3">
            <IonIcon icon={square} />
            <IonLabel>Tab 3</IonLabel>
          </IonTabButton> */}
        </IonTabBar>
      </IonTabs>
    </IonReactRouter>
  </IonApp>
)};

export default App;
